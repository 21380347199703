import Immutable from 'seamless-immutable';
import {stateGo} from 'redux-ui-router';
import partial from 'ramda/src/partial';
import __ from 'ramda/src/__';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerLocaleKeys from '../../../../../locales/keys';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    RouteParams
} from '../../../../../reducers/selectors/customer.selectors';
import {
    ActiveServicesSelector,
    BulkServicesCurrentPageCountSelector,
    BulkServicesCurrentPageNumberSelector,
    BulkServicesPageNumberSelector,
    BulkServicesPageSizeSelector,
    BulkServicesRecordCountSelector,
    BulkServicesSearchErrorSelector,
    BulkServicesSearchStringSelector,
    BulkServicesStatusesSelector,
    BulkServicesTableDataSelector,
    HasSuspendResumePermissionSelector,
    IncludeStatusFilterSelector,
    IncludeTypeOfServiceFilterSelector,
    IsFetchingBulkServices,
    IsServiceLifeCycleStatusUpdatableSelector,
    ServicesTypeSelector,
    ServicesWithFormattedServiceAttributeSelector
} from '../../../../../reducers/selectors/services.selectors';
import {ServiceFeatureOrderingUIFeatureToggleSelector} from '../../../../../reducers/selectors/customer.actions.template.selectors';
import {
    handleMoreOptionSelected,
    navigateToNonTelcoUsageDetails,
    navigateToUsageDetails,
    navigateToView,
    SERVICES_MORE_OPTIONS_CONSTANTS
} from './services.list.controller.helper';
import {
    searchServices,
    setBulkServicesPageSize,
    setBulkServicesSearchString,
    setBulkServicesStatusFilter,
    setCurrentPage,
    setServicesTypeOfServiceFilter
} from '../../../../../reducers/actions/services.actions';
import {
    ORDER_STATUS,
    ORDER_TYPES
} from '../../../orderHistory/orderDetails/order.details.constants';
import {searchOrders} from '../../../../../reducers/actions/order.history.actions';
import {
    createTableServicesListHeaders,
    DEFAULT_PAGE_NUMBER,
    TYPE_OF_SERVICE,
    NON_BULK_SERVICE_CATEGORIZATION
} from './services.list.constants';
import {retrieveConvergentBillerAccountDetails} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {SERVICE_DETAILS_STATE_NAME} from '../../services.and.usage.config';

class ServicesListController {
    constructor($ngRedux, $log, $scope, $timeout, uiNotificationService) {
        Object.assign(this, {
            $log,
            $ngRedux,
            $scope,
            $timeout,
            cellTypesOfService: {
                [TYPE_OF_SERVICE.BULK]: i18n.translate(CustomerLocaleKeys.SERVICES_LIST.TYPES.BULK),
                [TYPE_OF_SERVICE.NON_BULK]: i18n.translate(CustomerLocaleKeys.SERVICES_LIST.TYPES.NON_BULK)
            },
            CustomerLocaleKeys,
            handleTypeOfServiceFilter : this.handleTypeOfServiceFilter.bind(this),
            SERVICE_DETAILS_STATE_NAME,
            typeOfServiceText: i18n.translate(CustomerLocaleKeys.SERVICES_LIST.TYPE_OF_SERVICE),
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.FeatureToggleConfig,
            CODES.RegularExpression,
            CODES.ServiceAttribute,
            CODES.ServiceLifeCycleStatus,
            CODES.UnitsOfMeasure
        ];
        const mapStateToTarget = (store) => {
            return {
                activeServices: ActiveServicesSelector(store),
                attributeConfiguration: MetadataSelectors.codes.MetadataCodeTypeSelector(CODES.ServiceAttribute, store),
                bulkServices: ServicesWithFormattedServiceAttributeSelector(store),
                bulkServicesError: BulkServicesSearchErrorSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPageNumber: BulkServicesCurrentPageNumberSelector(store),
                hasSuspendResumeAccess: HasSuspendResumePermissionSelector(store),
                isCodeTableLoaded: MetadataCodeLoadedSelector(__, store),
                isFetchingBulkServices: IsFetchingBulkServices(store),
                isServiceFeatureOrderingEnabled: ServiceFeatureOrderingUIFeatureToggleSelector(store),
                isServiceLifeCycleStatusLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.ServiceLifeCycleStatus, store),
                isServiceLifeCycleStatusUpdatable: IsServiceLifeCycleStatusUpdatableSelector(store),
                isServiceReasonCodeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                isUnitOfMeasurementLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.UnitsOfMeasure, store),
                pageCount: BulkServicesCurrentPageCountSelector(store),
                pageNumber: BulkServicesPageNumberSelector(store),
                pageSize: BulkServicesPageSizeSelector(store),
                recordCount: BulkServicesRecordCountSelector(store),
                regexCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                regexCodes: MetadataSelectors.codes.MetadataCodeTypeSelector(CODES.RegularExpression, store),
                routeParams: RouteParams(store),
                searchString: BulkServicesSearchStringSelector(store),
                selectedStatusFilter: IncludeStatusFilterSelector(store),
                selectedTypeOfServiceFilter: IncludeTypeOfServiceFilterSelector(store),
                serviceAttributesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                statusCodes: BulkServicesStatusesSelector(store),
                tableData: BulkServicesTableDataSelector(store),
                typesOfServiceCodes: ServicesTypeSelector(store),
                userPageSizePreference: PageSizePreferenceSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeTypes,
            fetchCodeTypesThunk: MetadataActions.codes.fetchCodeTypesThunk,
            fetchRegexCodes: () => {
                return MetadataActions.codes.fetchCodeTypes(CODES.RegularExpression);
            },
            fetchServiceAttributes: MetadataActions.codes.fetchServiceAttributes,
            retrieveConvergentBillerAccountDetails,
            searchOrders,
            searchServices,
            setBulkServicesPageSize,
            setBulkServicesSearchString,
            setBulkServicesStatusFilter,
            setCurrentPage,
            setServicesTypeOfServiceFilter,
            stateGo
        };
        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
            this.grid ? this.grid.data = this.state.tableData : null;
        });

        codeTables.forEach((code) => {
            if (!this.state.isCodeTableLoaded(code)) {
                this.actions.fetchCodeTypesThunk(code).catch(this.onApiError);
            }
        });

        this.grid = this.servicesGridSetup();
        this.navigateToView = partial(navigateToView, [this.$ngRedux]);
        this.handleMoreOptionSelected = partial(handleMoreOptionSelected, [this.$scope, this.$log, this.$ngRedux, this.state.routeParams]).bind(this);
        this.navigateToUsageDetails = partial(navigateToUsageDetails, [this.state.routeParams]).bind(this);
        this.navigateToNonTelcoUsageDetails = partial(navigateToNonTelcoUsageDetails, [this.state.routeParams]).bind(this);

        //MoreMenuItems Map object
        this.moreCellOptions = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_SERVICE_DETAILS,
                title: i18n.translate(CustomerLocaleKeys.VIEW_SERVICE_DETAILS)
            }, {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_SERVICE_ATTRIBUTES,
                title: i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.VIEW_SERVICE_ATTRIBUTES)
            }
        ];

        this.moreCellOptionsForNonBulkActive = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            }
        ];

        this.moreCellOptionsForNonBulkActiveWithManageFeatures = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            },
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_MANAGE_FEATURES,
                title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS)
            }
        ];

        this.moreCellOptionsForNonBulkActiveLocked = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            }
        ];

        this.moreCellOptionsForNonBulkActiveLockedWithManageFeatures = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            },
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_MANAGE_FEATURES,
                title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS)
            }
        ];

        this.moreCellOptionsForNonBulkSuspended = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            }
        ];

        this.moreCellOptionsForNonBulkSuspendedWithManageFeatures = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS,
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS)
            },
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_MANAGE_FEATURES,
                title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS),
                disabled: true,
                isLocked: true
            }
        ];

        this.moreCellOptionsForRemoved = [
            {
                id: SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_SERVICE_ATTRIBUTES,
                title: i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.VIEW_SERVICE_ATTRIBUTES)
            }
        ];

        //Service can't be resumed because there is already a future date order to resume the service is in progress
        this.moreCellOptionsForNonBulkSuspendedResumeDisabled = [...this.moreCellOptionsForNonBulkSuspended];
        this.moreCellOptionsForNonBulkSuspendedResumeDisabledWithManageFeatures = [...this.moreCellOptionsForNonBulkSuspendedWithManageFeatures];

        //Service can't be suspended because there is already a future date order to suspend the service is in progress
        this.moreCellOptionsForNonBulkActiveLockedSuspendDisabled = [...this.moreCellOptionsForNonBulkSuspended];
        this.moreCellOptionsForNonBulkActiveLockedSuspendDisabledWithManageFeatures = [...this.moreCellOptionsForNonBulkSuspendedWithManageFeatures];

        if (this.state.isServiceLifeCycleStatusUpdatable) {
            this.moreCellOptionsForNonBulkActive.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.UPDATE_SERVICE_LIFECYCLE_STATUS,
                title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
            });

            this.moreCellOptionsForNonBulkActiveWithManageFeatures.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.UPDATE_SERVICE_LIFECYCLE_STATUS,
                title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
            });
        }

        if (this.state.hasSuspendResumeAccess) {
            this.moreCellOptionsForNonBulkActive.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
            });
            this.moreCellOptionsForNonBulkActiveWithManageFeatures.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
            });
            this.moreCellOptionsForNonBulkActiveLocked.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE),
                disabled: true,
                isLocked: true,
                hasTooltip: true,
                tooltip: {
                    context: {
                        description: i18n.translate(CustomerLocaleKeys.SUSPENDED_SERVICE_ERROR)
                    },
                    template: require('./suspend.service.tooltip.html'),
                    icon: 'lock'
                }
            });
            this.moreCellOptionsForNonBulkActiveLockedWithManageFeatures.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE),
                disabled: true,
                isLocked: true,
                hasTooltip: true,
                tooltip: {
                    context: {
                        description: i18n.translate(CustomerLocaleKeys.SUSPENDED_SERVICE_ERROR)
                    },
                    template: require('./suspend.service.tooltip.html'),
                    icon: 'lock'
                }
            });
            this.moreCellOptionsForNonBulkSuspended.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.RESUME_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
            });

            this.moreCellOptionsForNonBulkSuspendedWithManageFeatures.push({
                id: SERVICES_MORE_OPTIONS_CONSTANTS.RESUME_SERVICE,
                title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
            });

            this.moreCellOptionsForNonBulkSuspendedResumeDisabled.push({
                disabled: true,
                id: SERVICES_MORE_OPTIONS_CONSTANTS.RESUME_SERVICE,
                isLocked: true,
                title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
            });
            this.moreCellOptionsForNonBulkSuspendedResumeDisabledWithManageFeatures.push({
                disabled: true,
                id: SERVICES_MORE_OPTIONS_CONSTANTS.RESUME_SERVICE,
                isLocked: true,
                title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
            });
            this.moreCellOptionsForNonBulkActiveLockedSuspendDisabled.push({
                disabled: true,
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                isLocked: true,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
            });
            this.moreCellOptionsForNonBulkActiveLockedSuspendDisabledWithManageFeatures.push({
                disabled: true,
                id: SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE,
                isLocked: true,
                title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
            });
        }

        this.moreCellOptionsMap = {
            [TYPE_OF_SERVICE.BULK]: [...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK]: [...this.moreCellOptionsForNonBulkActive, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_WITH_MANAGED_FEATURES]: [...this.moreCellOptionsForNonBulkActiveWithManageFeatures, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_SUSPEND_DISABLED]: [...this.moreCellOptionsForNonBulkActiveLockedSuspendDisabled, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_SUSPEND_DISABLED_WITH_MANAGED_FEATURES]: [...this.moreCellOptionsForNonBulkActiveLockedSuspendDisabledWithManageFeatures, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK]: [...this.moreCellOptionsForNonBulkSuspended, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES]: [...this.moreCellOptionsForNonBulkSuspendedWithManageFeatures, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED]: [...this.moreCellOptionsForNonBulkSuspendedResumeDisabled, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED_WITH_MANAGED_FEATURES]: [...this.moreCellOptionsForNonBulkSuspendedResumeDisabledWithManageFeatures, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK]: [...this.moreCellOptionsForNonBulkActiveLocked, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.LOCKED_SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES]: [...this.moreCellOptionsForNonBulkActiveLockedWithManageFeatures, ...this.moreCellOptions],
            [NON_BULK_SERVICE_CATEGORIZATION.REMOVED]: [...this.moreCellOptionsForRemoved]
        };

        this.$scope.datatable = {
            cellTypesOfService: this.cellTypesOfService,
            handleMoreOptionSelected: this.handleMoreOptionSelected,
            moreCellOptionsMap: this.moreCellOptionsMap,
            onViewSelected: this.navigateToView,
            navigateToUsageDetails: this.navigateToUsageDetails,
            navigateToNonTelcoUsageDetails: this.navigateToNonTelcoUsageDetails,
            TYPE_OF_SERVICE,
            SERVICE_DETAILS_STATE_NAME
        };


        //Sets the name of the UsageCaps Item because it wasn't loaded when the map objects were created.
        Object.keys( this.$scope.datatable.moreCellOptionsMap || {} ).forEach((el) => {
            const usageCapsMenuItemIndex = this.$scope.datatable.moreCellOptionsMap[el].findIndex((cellOption) => {
                return cellOption.id === SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS;
            });
            if (usageCapsMenuItemIndex > -1) {
                this.$scope.datatable.moreCellOptionsMap[el][usageCapsMenuItemIndex].title = i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS);
            }
        });

        if (!this.state.isServiceReasonCodeLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ReasonCodes);
        }
        if (!this.state.isUnitOfMeasurementLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.UnitsOfMeasure);
        }
        this.isDataLoading = () => {
            return this.state.isFetchingBulkServices;
        };
        this.attributeModalConfig = {
            onRegisterApi: (event) => {
                this.attributeModalAPI = event.api;
            }
        };
        this.updateServiceLifeCycleStatusConfig = {
            onRegisterApi: (event) => {
                this.updateServiceLifeCycleStatusModalAPI = event.api;
            }
        };

        if (!this.state.serviceAttributesLoaded) {
            this.actions.fetchServiceAttributes();
        }
        if (!this.state.regexCodesLoaded) {
            this.actions.fetchRegexCodes();
        }
        if (!this.state.isServiceLifeCycleStatusLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ServiceLifeCycleStatus)
                .then(() => {
                    //Sets the update lifecycle status option because it wasn't loaded when the map objects were created.
                    if (this.state.isServiceLifeCycleStatusUpdatable) {
                        this.$scope.datatable.moreCellOptionsMap[NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK].push({
                            id: SERVICES_MORE_OPTIONS_CONSTANTS.UPDATE_SERVICE_LIFECYCLE_STATUS,
                            title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
                        });

                        this.$scope.datatable.moreCellOptionsMap[NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_WITH_MANAGED_FEATURES].push({
                            id: SERVICES_MORE_OPTIONS_CONSTANTS.UPDATE_SERVICE_LIFECYCLE_STATUS,
                            title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
                        });
                    }
                });
        }
        this.handleStatusFilter = (selected) => {
            this.actions.setBulkServicesStatusFilter(selected);
            this.onPageSelected(DEFAULT_PAGE_NUMBER);
        };
        this.onPageSelected(DEFAULT_PAGE_NUMBER);
    }

    $onDestroy() {
        this.disconnect();
    }

    closeAttributeModal() {
        this.attributeModalAPI.close();
        this.showAttributeModal = false;
    }

    onCloseUpdateServiceLifeCycleStatusModal() {
        this.showUpdateServiceLifeCycleStatusModal = false;
        this.updateServiceLifeCycleStatusModalAPI.close();
    }

    openServiceAttributes(item) {
        this.editAttributes = Immutable([]);
        const attributes = this.state.bulkServices.serviceThumbnails.find((thumbnail) => {
            return thumbnail.ServiceIdentifier === item.ServiceIdentifier.Value;
        });
        this.availableAttributes = Immutable(attributes ? attributes.ServiceAttributeValues : []);
        this.attributeModalContentTitle = i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.SERVICE_ATTRIBUTES.TITLE);
        this.showAttributeModal = true;
        this.$timeout(this.attributeModalAPI.open);
    }
    openUpdateServiceLifeCycleStatus(item) {
        this.ServiceIdentifier = item.ServiceIdentifier.Value;
        this.showUpdateServiceLifeCycleStatusModal = true;
        this.$timeout(this.updateServiceLifeCycleStatusModalAPI.open);
    }
    pageSizeOptionSelected(pageSize) {
        this.actions.setBulkServicesPageSize(pageSize);
        this.onPageSelected(DEFAULT_PAGE_NUMBER);
    }
    onPageSelected(pageNumber) {
        if (!this.state.pageSize) {
            this.actions.setBulkServicesPageSize(this.state.userPageSizePreference);
        }
        this.actions.setCurrentPage(pageNumber);
        const params = Object.assign({}, this.state.routeParams, {
            pageNumber: pageNumber
        });
        params.status = this.state.selectedStatusFilter ? this.state.selectedStatusFilter.value : null;
        params.serviceType = this.state.selectedTypeOfServiceFilter ? this.state.selectedTypeOfServiceFilter.value : null;
        params.searchIdentifier = this.state.searchString;
        params.pageSize = this.state.pageSize;
        this.actions.searchServices(params, {
            pageNumber: pageNumber
        }).then(() => {
            this.searchOpenOrders().then(() => {
                this.actions.retrieveConvergentBillerAccountDetails(this.state.currentCustomerId);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        });
    }
    handleExpansion(item) {
        item.isExpanded = !item.isExpanded;
    }
    searchChanged(searchText) {
        this.actions.setBulkServicesSearchString(searchText);
        this.onPageSelected(DEFAULT_PAGE_NUMBER);
    }
    handleTypeOfServiceFilter(selected) {
        this.actions.setServicesTypeOfServiceFilter(selected);
        this.onPageSelected(DEFAULT_PAGE_NUMBER);
    }
    servicesGridSetup() {
        return {
            columnDefs: createTableServicesListHeaders(),
            data: this.state.tableData
        };
    }
    onApiError(error) {
        this.uiNotificationService.transientError(error.translatedMessage);
    }

    searchOpenOrders() {
        const filters = {
            Statuses : [ORDER_STATUS.OPEN],
            Types : [ORDER_TYPES.RESTORE, ORDER_TYPES.SUSPEND]
        };
        return this.actions.searchOrders(this.state.currentCustomerId, filters);
    }

}

export default {
    bindings: {
        onOpenSuspendService: '&'
    },
    controller: ServicesListController,
    template: require('./services.list.html')
};
