import find from 'ramda/src/find';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import __ from 'ramda/src/__';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {CUSTOMER_PAGE_IDS} from 'invision-core/src/components/configurable/customercare';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {IsOfferingMetadataLoadingSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {FeatureToggleConstants} from 'invision-core';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import localeKeys from './../../../locales/keys';
import {stateGo} from 'redux-ui-router';
import {
    searchCustomerRemarks,
    setRemarkBeingEdited
} from '../../../reducers/actions/customer.remarks.actions';
import {
    HasAdminRemarkOverwriteAccessSelector,
    HasSubscriberAdminAccessSelector
} from '../../../reducers/selectors/permissions.selectors';
import {
    CurrentAccountNumberSelector,
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerSelector,
    IsSubscriberStateReloadRequiredSelector,
    RouteParams,
    RouteState
} from '../../../reducers/selectors/customer.selectors';
import {
    CurrentRemarksSelector,
    IsFetchingRemarksSelector,
    IsRemarksServiceAvailableSelector
} from '../../../reducers/selectors/customer.remarks.selectors';
import {OFFERING_STATUS_TYPES} from '../offerings/offerings.constants';
import {
    AccountsNumberSelector,
    ConvergentBillerAccountDetailsSelector,
    ConvergentBillerAccountHasBulkProducts,
    CurrentAccountSummarySelector,
    IsARCSubscriptionMgmtFeatureToggleSelector,
    IsCurrentAccountPostpaidSelector,
    IsFetchingSubscriberSummarySelector,
    OfferingIsOffCycleMapSelector,
    NumberOfAccountsSelector
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {SelectedSubscriberOfferingInstanceIdSelector} from '../../../reducers/selectors/search.subscriber.offerings.selectors';
import {
    IsFetchingWidgetDataSelector,
    IsInGracePeriodSelector,
    ShowAccountBalanceWidgetSelector,
    ShowAccountStatusWidgetSelector,
    ShowActiveProductsWidgetSelector,
    ShowDeviceFinancingWidgetSelector,
    ShowOfferingsWidgetSelector,
    ShowServicesDeprecatedWidgetSelector,
    ShowOfferingDeprecatedWidgetsSelector,
    ShowProductsWidgetSelector,
    ShowServicesWidgetSelector,
    ShowSubscriptionsWidgetSelector,
    ShowTreatmentWidgetSelector,
    VisibleWidgetsSelector
} from '../../../reducers/selectors/dashboard.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    ActiveContentCollectionSelector,
    ActiveItemCountSelector,
    IsFetchingContentActivitySelector,
    HasActiveContentActivitySelector
} from '../../../reducers/selectors/customer.content.activity.selectors';
import {
    cancelContentActivity,
    fetchCustomerContentActivity
} from '../../../reducers/actions/customer.content.activity.actions';
import {retrieveCustomerDataForOttDashboard} from '../../../reducers/actions/ott.dashboard.actions';
import {retrieveSubscriberFinanceDetails} from '../../../reducers/actions/customer.devices.actions';
import {
    retrieveConvergentBillerAccountDetails,
    retrieveConvergentBillerSubscriberSummary
} from '../../../reducers/actions/customer.convergent.biller.actions';
import {
    CustomerTemplateWidgetSelector,
    CustomerActionsTemplateSelector,
    NoCustomWidgetsConfiguredSelector
} from '../../../reducers/selectors/customer.actions.template.selectors';
import {FormattedInteractionsSelector} from '../../../reducers/selectors/customer.interactions.selectors';
import {fetchInteractions} from '../../../reducers/actions/customer.interaction.actions';
import {NOTIFICATION_TIME_LENGTH} from '../../../customercare.constants';
import {IsCustomerWrapUpRequiredSelector} from '../../../reducers/selectors/search.selectors';
import {
    retrieveCustomer,
    retrieveSubscriberOfferingDetail,
    setReloadSubscriberStateFlag
} from '../../../reducers/actions/customer.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    getStandaloneProduct,
    searchSubscriberOfferings
} from '../../../reducers/actions/offering.order.actions';
import {
    retrieveServiceDetails,
    searchServices
} from '../../../reducers/actions/services.actions';
import {SelectedServiceIdentifierSelector} from '../../../reducers/selectors/selected.service.details.selectors';
import {NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH} from './dbssDashboard/servicesAndOffers/services.and.offers.contants';
import {TYPE_OF_SERVICE} from '../servicesAndUsage/servicesAndSharedEntitlements/services/services.list.constants';
import {ACCOUNTING_METHODS_NAMES} from '../makePayment/make.payment.constants';
import {IsAutoPayEnabledSelector} from '../../../reducers/selectors/autopay.configuration.selectors';
class DashboardController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            ACCOUNTING_METHODS_NAMES,
            caseModalConfigApi: null,
            depositsModalConfigApi: null,
            disconnectRedux: null,
            editRemark: this.editRemark.bind(this),
            isPaymentFailureNotificationClosed: false,
            isTabActive: this.isTabActive.bind(this),
            getWidgetToDisplay: this.getWidgetToDisplay.bind(this),
            onAccountSelect: this.onAccountSelect.bind(this),
            isContentActivityAvailable: this.isContentActivityAvailable.bind(this),
            refreshActiveCount: this.refreshActiveCount.bind(this),
            refreshRemarks: this.refreshRemarks.bind(this),
            offeringMetadataLoaded: false,
            retrieveConvergentBillerAndDeviceFinancingDetails: this.retrieveConvergentBillerAndDeviceFinancingDetails.bind(this),
            tabSelected: this.tabSelected.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.FeatureToggleConfig,
            CODES.OneTimeBillerRuleConfiguration,
            CODES.RecurringBillerRuleConfiguration,
            CODES.RegularExpression,
            CODES.ServiceAttribute,
            CODES.SubscriberSearchConfig
        ];
        const mapStateToTarget = (store) => {
            return {
                accountNumbers: AccountsNumberSelector(store),
                activeItemCount: ActiveItemCountSelector(store),
                businessUnitIsDbss: IsDbss(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                currentAccountDetails: ConvergentBillerAccountDetailsSelector(store),
                currentAccountNumber: CurrentAccountNumberSelector(store),
                currentAccountSummary: CurrentAccountSummarySelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
                currentCustomerActiveContent: ActiveContentCollectionSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomerInteractions: FormattedInteractionsSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                currentCustomerRemarks: CurrentRemarksSelector(store),
                currentRoute: RouteState(store),
                customerActionsTemplate: CustomerActionsTemplateSelector(store),
                customerTemplateWidgets: CustomerTemplateWidgetSelector(store),
                depositTypes: MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(CODES.DepositType, store),
                depositTypesLoaded: MetadataCodeLoadedSelector(CODES.DepositType, store),
                features: FeatureTogglesSelector(store),
                hasActiveContent: HasActiveContentActivitySelector(store),
                hasAdminRemarkOverwriteAccess: HasAdminRemarkOverwriteAccessSelector(store),
                hasBulkProducts: ConvergentBillerAccountHasBulkProducts(store),
                hasSubscriberAdminAccess: HasSubscriberAdminAccessSelector(store),
                interactionTypes: MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(CODES.InteractionType, store),
                interactionTypesLoaded: MetadataCodeLoadedSelector(CODES.InteractionType, store),
                isARCSubscriptionMgmtFeatureLoaded: MetadataCodeLoadedSelector(CODES.MicroserviceConfiguration, store),
                isARCSubscriptionMgmtFeatureToggle:IsARCSubscriptionMgmtFeatureToggleSelector(store),
                isBusinessUnitTemplateLoaded: MetadataCodeLoadedSelector(CODES.BusinessUnitTemplate, store),
                isCurrentAccountPostpaid: IsCurrentAccountPostpaidSelector(store),
                isCustomerWrapUpRequired: IsCustomerWrapUpRequiredSelector(store),
                isFetchingAccountSummary: IsFetchingSubscriberSummarySelector(store),
                isFetchingActiveContent: IsFetchingContentActivitySelector(store),
                isFetchingRemarks: IsFetchingRemarksSelector(store),
                isFetchingWidgetData: IsFetchingWidgetDataSelector(store),
                isInGracePeriod: IsInGracePeriodSelector(store),
                isOfferingMetadataLoading: IsOfferingMetadataLoadingSelector(store),
                isRemarksServiceAvailable: IsRemarksServiceAvailableSelector(store),
                isSubscriberStateReloadRequired: IsSubscriberStateReloadRequiredSelector(store),
                noCustomWidgetsConfigured: NoCustomWidgetsConfiguredSelector(store),
                numberOfAccounts: NumberOfAccountsSelector(store),
                offeringIsOffCycleMap: OfferingIsOffCycleMapSelector(store),
                routeParams: RouteParams(store),
                selectedOfferingInstanceId: SelectedSubscriberOfferingInstanceIdSelector(store),
                selectedServiceIdentifier: SelectedServiceIdentifierSelector(store),
                showAccountBalanceWidget: ShowAccountBalanceWidgetSelector(store),
                showAccountStatusWidget: ShowAccountStatusWidgetSelector(store),
                showActiveProductsWidget: ShowActiveProductsWidgetSelector(store),
                showAutoPay: IsAutoPayEnabledSelector(store),
                showDeviceFinancingWidget: ShowDeviceFinancingWidgetSelector(store),
                showOfferingsWidget: ShowOfferingsWidgetSelector(store),
                showServicesWidget: ShowServicesWidgetSelector(store),
                showOfferingDeprecatedWidgets: ShowOfferingDeprecatedWidgetsSelector(store),
                showProductsWidget: ShowProductsWidgetSelector(store),
                showServicesDeprecatedWidget: ShowServicesDeprecatedWidgetSelector(store),
                showSubscriptionsWidget: ShowSubscriptionsWidgetSelector(store),
                showTreatmentWidget: ShowTreatmentWidgetSelector(store),
                transitionConfigurationLoaded: MetadataCodeLoadedSelector(CODES.TransitionConfiguration, store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                visibleCustomerTemplateWidgets: VisibleWidgetsSelector(store)
            };
        };

        this.customerCareKeys = localeKeys;

        const controllerActions = {
            cancelContentActivity,
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            fetchCustomerContentActivity,
            fetchInteractions,
            getStandaloneProduct,
            retrieveConvergentBillerAccountDetails,
            retrieveConvergentBillerSubscriberSummary,
            retrieveCustomerDataForOttDashboard,
            retrieveCustomer,
            retrieveOfferingsMetadata,
            retrieveServiceDetails,
            retrieveSubscriberFinanceDetails,
            retrieveSubscriberOfferingDetail,
            searchCustomerRemarks,
            searchServices,
            searchSubscriberOfferings,
            setReloadSubscriberStateFlag,
            setRemarkBeingEdited,
            stateGo
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.stateOrName = this.state.currentRoute;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        if (this.state.currentCustomerId) {
            if (!this.state.transitionConfigurationLoaded) {
                this.actions.fetchCodeTypes(CODES.TransitionConfiguration);
            }
            if (!this.state.isARCSubscriptionMgmtFeatureLoaded) {
                this.actions.fetchCodeTypes(CODES.MicroserviceConfiguration);
            }
            this.actions.retrieveCustomerDataForOttDashboard(this.state.currentCustomerId);
            this.actions.fetchInteractions({
                customerId: this.state.currentCustomerId,
                PageSize: 10,
                SearchBySubscriber: true
            });

            if (this.state.businessUnitIsDbss) {
                this.actions.fetchCodeTypes(CODES.FeatureToggleConfig).then(() => {
                    this.retrieveConvergentBillerAndDeviceFinancingDetails().then(() => {
                        const offeringIds = pluck('OfferingId', this.state.currentAccountDetails.offerings);
                        return this.actions.retrieveOfferingsMetadata(offeringIds).then(() => {
                            this.offeringMetadataLoaded = true;
                        });
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                });
            }
        }

        if (this.state.currentCustomer.ConvergentBillerId && !this.state.isFetchingAccountSummary) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId, true);
        }

        // CodeType InteractionType is a required sequential fetch inside retrieveCustomerDataForOttDashboard. This prevents an unnecessary fetch.
        if (!this.state.interactionTypesLoaded && !this.state.currentCustomerId) {
            this.actions.fetchCodeTypes(CODES.InteractionType);
        }

        if (!this.state.depositTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.DepositType);
        }

        if (!this.state.isBusinessUnitTemplateLoaded) {
            this.actions.fetchCodeTypes(CODES.BusinessUnitTemplate)
                .then(() => {
                    this.isContentActivityAvailable();
                });
        }

        this.createRemarkConfig = {
            onRegisterApi: ({api: {open}}) => {
                this.openCreateRemarkPopup = open;
            }
        };

        this.terminateStreamPopupOption = {
            onRegisterApi: (evt) => {
                this.terminateStreamPopupApi = evt.api;
            }
        };

        this.removeDownloadPopupOption = {
            onRegisterApi: (evt) => {
                this.removeDownloadPopupApi = evt.api;
            }
        };

        this.tabPanelShouldOpen = false;
        this.remarksAndActivityTabs = [{
            id: 0,
            glyph: 'align-left',
            active: false
        }, {
            id: 1,
            glyph: 'comment',
            active: false,
            notificationCount: null
        }];

        if (!this.state.isFetchingRemarks && this.state.isRemarksServiceAvailable) {
            this.refreshRemarks();
        }

        if (this.state.isSubscriberStateReloadRequired) {
            this.$timeout(() => {
                this.actions.retrieveCustomer(this.state.currentCustomerId);
            });
        }
        this.isContentActivityAvailable();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    closePaymentFailedNotification() {
        this.isPaymentFailureNotificationClosed = true;
    }

    isContentActivityAvailable() {
        this.isContentActivityEnabled = this.state.customerActionsTemplate.pages.find((page) => {
            if (page.id === CUSTOMER_PAGE_IDS.ACTIVITY) {
                return page.subNavigation.find((item) => {
                    return item.id === CUSTOMER_PAGE_IDS.CONTENT_ACTIVITY;
                });
            }
        });


        if (!this.state.businessUnitIsDbss && this.state.isBusinessUnitTemplateLoaded && this.isContentActivityEnabled) {
            const contentActivityTab = this.remarksAndActivityTabs.find((item) => {
                return item.id === 2;
            });

            if (!contentActivityTab) {
                this.fetchCustomerContentActivity();
                this.remarksAndActivityTabs.push({
                    id: 2,
                    glyph: 'clock-o',
                    active: false,
                    hasEmphasis: true,
                    notificationCount: this.state.activeItemCount
                });
            }
        }
    }

    getWidgetToDisplay(widget) {
        if (widget.displayServices) {
            return this.state.showServicesWidget;
        } else if (widget.displayOfferings) {
            return this.state.showOfferingsWidget;
        } else {
            return widget.displayServicesAndOfferingsWidget;
        }
    }

    // right tab column
    isTabActive(tabIndex) {
        return this.remarksAndActivityTabs.some((tab) => {
            return tab.id === tabIndex && tab.active;
        });
    }

    tabSelected(tabSelected) {
        this.remarksAndActivityTabs.forEach((tab) => {
            if (tab.id === tabSelected.id) {
                this.tabPanelShouldOpen = tab.active ? !this.tabPanelShouldOpen : true;
                tab.active = !tab.active;
            } else {
                tab.active = false;
            }
        });
    }
    refreshActiveCount() {
        const tab = find(propEq(2, 'id'))(this.remarksAndActivityTabs);
        if (tab) {
            tab.notificationCount = this.state.activeItemCount;
        }
    }
    refreshRemarks() {
        const remarksTab = find(propEq(1, 'id'))(this.remarksAndActivityTabs);

        this.actions.searchCustomerRemarks(this.state.currentCustomerId).then(
            () => {
                if (remarksTab) {
                    remarksTab.notificationCount = this.state.currentCustomerRemarks.length;
                }
            },
            () => {
                if (remarksTab) {
                    remarksTab.notificationCount = null;
                }
            });
    }

    retrieveConvergentBillerAndDeviceFinancingDetails() {
        const promises = [];

        promises.push(this.actions.retrieveConvergentBillerAccountDetails(this.state.currentCustomerId).then(() => {
            const productMetadataPromises = [];
            (this.state.currentAccountDetails.standalones || []).forEach((standalone) => {
                productMetadataPromises.push(this.actions.getStandaloneProduct(standalone.ProductId));
            });
            Promise.all(productMetadataPromises);
        }));

        if (this.state.currentCustomer.ConvergentBillerId && !this.state.features[FeatureToggleConstants.OFFCYCLE_BYPASS_ARM]) {
            promises.push(this.actions.retrieveSubscriberFinanceDetails(this.state.currentCustomerId));
        }

        return Promise.all(promises);
    }

    onAccountSelect(account) {
        this.actions.stateGo('index.customercare.customer.accountDashboard', {
            accountNumber: account.number
        });
    }
    editRemark(remark) {
        this.openCreateRemarkPopup();
        this.actions.setRemarkBeingEdited(remark);
    }
    onConfigureAutoPayBtnClicked() {
        this.actions.stateGo('index.customercare.customer.autoPay', {
            accountNumber: this.state.currentAccountNumber
        });
    }

    fetchCustomerContentActivity() {
        this.actions.fetchCustomerContentActivity(this.state.currentCustomerId, {
            IncludeDownloads: false,
            PageSize: 1 // Workaround to *not* fetch Archived Activity. We'd prefer 0, but minimum is 1
        }).then(() => {
            this.refreshActiveCount();
        });
    }
    handleToggleNavigatorCollapsedSelected() {
        this.actions.toggleCollapsedSelected();
    }

    // dialog methods
    openDialog(contentActivity) {
        this.contentActivityToTerminate = contentActivity;
        return this.contentActivityToTerminate.Download ? this.removeDownloadPopupApi.open() : this.terminateStreamPopupApi.open();
    }

    // Download dialog.
    closeRemoveDownloadDialog() {
        this.removeDownloadPopupApi.close();
    }
    removeDownloadDialogResult(remove) {
        if (remove) {
            this.terminateContentActivity(this.contentActivityToTerminate);
        }

        this.contentActivityToTerminate = null;
        this.closeRemoveDownloadDialog();
    }

    // Terminate Stream dialog.
    closeTerminateStreamDialog() {
        this.terminateStreamPopupApi.close();
    }
    terminateContentActivity(content) {
        const params = {
            Reference: content.Reference,
            PricingPlanId: content.PricingPlanId
        };
        this.actions.cancelContentActivity(this.state.currentCustomerId, params)
            .then(() => {
                const localeToUse = content.Download ? this.customerCareKeys.CONTENT_ACTIVITY.CANCEL_DOWNLOAD.TERMINATED :
                    this.customerCareKeys.CONTENT_ACTIVITY.CANCEL_STREAM.TERMINATED;
                return this.uiNotificationService.success(i18n.translate(localeToUse), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            })
            .then(() => {
                this.fetchCustomerContentActivity();
            })
            .catch(() => {
                this.uiNotificationService.error(this.state.errorMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }
    terminateStreamDialogResult(shouldTerminateStream) {
        if (shouldTerminateStream) {
            this.terminateContentActivity(this.contentActivityToTerminate);
        }

        this.contentActivityToTerminate = null;
        this.closeTerminateStreamDialog();
    }

    refreshConvergentBillerDetails() {
        this.retrieveConvergentBillerAndDeviceFinancingDetails();
        this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId, true);
    }

    refreshServices() {
        const params = Object.assign({}, this.state.routeParams, {
            serviceType: TYPE_OF_SERVICE.NON_BULK,
            pageNumber: 1,
            pageSize: NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH
        });

        this.actions.searchServices(params).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        if (this.state.selectedServiceIdentifier) {
            const serviceIdentifiers = [{
                Value: this.state.selectedServiceIdentifier
            }];
            this.actions.retrieveServiceDetails(this.state.currentCustomerId, serviceIdentifiers).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    refreshOfferings() {
        const params = Object.assign({}, this.state.routeParams, {
            includeOfferingTotals: true,
            pageNumber: 1,
            pageSize: NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH,
            status: [
                OFFERING_STATUS_TYPES.ACTIVE,
                OFFERING_STATUS_TYPES.PENDING_ACTIVE,
                OFFERING_STATUS_TYPES.PENDING_REMOVED,
                OFFERING_STATUS_TYPES.SUSPENDED,
                OFFERING_STATUS_TYPES.SUSPENSION_SCHEDULED
            ]
        });

        this.actions.searchSubscriberOfferings(params).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        this.actions.retrieveSubscriberOfferingDetail(
            this.state.currentCustomerId,
            this.state.selectedOfferingInstanceId,
            this.state.offeringIsOffCycleMap?.[this.state.selectedOfferingInstanceId]
        ).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }
}

export default {
    template: require('./dashboard.html'),
    require: {
        customerCtrl: '^customer'
    },
    bindings: {},
    controller: DashboardController,
    controllerAs: 'DashboardController'
};
